import React, { useEffect, useState } from "react";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CONSTANTS } from "../../constants/Constants";
import { fit } from '../../utils';
import { activatePill } from "../../utils";
import { navigate } from "@reach/router";

const sel = {
    horizontal: '.horizontal-scroll',
    horizontalWrapper: '.horizontal_wrapper',
    canvas: '#horizontal_bg',
    bgMobile: '.horizontal_bg--mobile',
    futureContainer: '.future .container',
    toyContainer: '.not-a-toy .container',
    forYouContainer: '.for-you .container',
    kido: '.kido'
}

function handleAnimationMobile() {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: sel.horizontal,
            start: 'top top',
            end: '+=220%',
            scrub: true,
            pin: true
        },
    });

    // tl.fromTo(sel.futureContainer, { opacity: 1, y: '0rem' }, { opacity: 0, x:'-5rem', duration: 0.6 })
    // .addLabel('first-text', '<')
    // .fromTo(sel.toyContainer, { opacity: 0 }, { opacity: 1, duration: 0.6}, '+=1.2')
    // .to(sel.toyContainer, { opacity: 0, duration: 0.6 }, '+=0.3')
    // .addLabel('second-text', '<')
    // .fromTo(sel.forYouContainer, { opacity: 0, y: '-5rem'}, { opacity: 1, y: '0rem', duration: 0.6})
    // .addLabel('third-text', '<')
    // .fromTo(sel.bgMobile, { x: '0'}, { x: '-200vw', ease: 'none', duration: 3.6 }, '-=3.4')
    // .fromTo(sel.horizontalWrapper, { x: '0'}, { x: '-200vw', ease: 'none', duration: 3.6 }, '-=3.6');

    tl.fromTo(sel.bgMobile, { x: '0'}, { x: '-200vw', ease: 'none', duration: 3.6 }, '-=3.4')
    .fromTo(sel.horizontalWrapper, { x: '0'}, { x: '-200vw', ease: 'none', duration: 3.6 }, '-=3.6');
}

function swapOnScroll() {
    const canvas = document.querySelector(sel.canvas);
    const context = canvas.getContext("2d");

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const frameCount = 241;
    const currentFrame = index => `/images/cable-animation/${(index + 1).toString()}.jpg`;

    const images = []
    const airpods = {
        frame: 0
    };

    for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        images.push(img);
    }

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: sel.horizontal,
            start: 'top top',
            end: '+=500%',
            pin: true,
            scrub: 0.2
        },
    });
    
    // tl.set(sel.kido, { visibility: 'visible' })
    //   .to(airpods, { frame: frameCount - 1, snap: "frame", onUpdate: render, duration: 1.5})
    //   .fromTo(sel.futureContainer, { opacity: 0, y: '5rem'}, { opacity: 1, y: '0rem', duration: 0.3}, '-=2')
    //   .to(sel.futureContainer, { opacity: 0, x:'-5rem', duration: 0.2 }, '-=1.7')
    //   .addLabel('first-text', '<')
    //   .fromTo(sel.toyContainer, { opacity: 0 }, { opacity: 1, duration: 0.15}, '-=1')
    //   .to(sel.toyContainer, { opacity: 0, duration: 0.15 }, '-=0.8')
    //   .addLabel('second-text', '<')
    //   .fromTo(sel.forYouContainer, { opacity: 0, y: '-5rem'}, { opacity: 1, y: '0rem', duration: 0.1}, '-=0.05')
    //   .addLabel('third-text', '<')
    //   .to(sel.horizontalWrapper, { x: '-200vw', ease: 'none', duration: 1 }, '-=1.4');

    // tl.set(sel.kido, { visibility: 'visible' })
    //   .to(airpods, { frame: frameCount - 1, snap: "frame", onUpdate: render, duration: 1.5})
    //   .to(sel.horizontalWrapper, { x: '-200vw', ease: 'none', duration: 1 }, '-=1.4');

    tl.set(sel.kido, { visibility: 'visible' })
      .to(airpods, { frame: frameCount - 1, snap: "frame", onUpdate: render, duration: 1.5})
      .fromTo(sel.futureContainer, { opacity: 0, y: '5rem'}, { opacity: 1, y: '0rem', duration: 0.3}, '-=2')
      .to(sel.futureContainer, { opacity: 0, duration: 0.3 }, '-=1.4')
      .addLabel('first-text', '<')
      .fromTo(sel.toyContainer, { opacity: 0 }, { opacity: 1, duration: 0.3}, '-=1.2')
      .addLabel('second-text', '<')
      .fromTo(sel.forYouContainer, { opacity: 0 }, { opacity: 1, duration: 0.3}, '-=1')
      .addLabel('third-text', '<')
      .to(sel.horizontalWrapper, { x: '-200vw', ease: 'none', duration: 1 }, '-=1.4');

    images[0].onload = render;
    const cover = fit(false);
    const {
        offsetX, 
        offsetY, 
        width, 
        height
    } = cover(canvas.width, canvas.height, 1920, 1080, 1, 1, 1);

    function render() {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(images[airpods.frame], offsetX, offsetY, width, height);
        gsap.set(sel.kido, { x: `${ 200 - (airpods.frame / frameCount) * 300 }vw`, y: `${ 10 - Math.sqrt(airpods.frame / frameCount) * 15 }vh`})
    }
}

export default function Future() {
    const { chargers } = CONSTANTS.section;
    
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.matchMedia({
            "(max-width: 1024px)": function() {
                handleAnimationMobile();
            },
            "(min-width: 1025px)": function() {
                swapOnScroll();
            }
        });
        gsap.timeline({
            scrollTrigger: {
                trigger: '.chargersObserver',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.chargers.id);
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.chargers.id);
                }
            }
        });
        
        gsap.timeline({
            scrollTrigger: {
                trigger: '.chargersObserver2',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.chargers.id);
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.chargers.id);
                }
            }
        });
        
        gsap.timeline({
            scrollTrigger: {
                trigger: '.chargersObserver3',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.chargers.id);
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.chargers.id);
                }
            }
        });
    }, []);
    return (
        <>
            <div className="horizontal-scroll" id={chargers.id} data-section-name={chargers.name}>

                <div className="kido"></div>
                <canvas className="horizontal_bg" id="horizontal_bg" />
                <img className="horizontal_bg--mobile"
                    src="/images/cable-animation/panorama_mobile.jpg"
                    loading="lazy"
                />
                <img className="horizontal_bg--mobile horizontal_bg--mobile-wide"
                    src="/images/cable-animation/panorama.jpg"
                    loading="lazy"
                />
                <div className="horizontal_wrapper">
                    <section className="fullscreen future observe chargersObserver" data-section-type={CONSTANTS.sectionType.light}>
                        <div className="future_bg" />
                        <div className="container">
                            <h2 className="future_title first-part">
                                Przepustka do przyszłości
                            </h2>
                            <h2 className="future_title second-part">
                                jak się patrzy
                            </h2>
                            <p className="future_subtitle">
                                Zaawansowana i&nbsp;bezpieczna stacja ładowania - tak mocna, jak nasza wiara <br />
                                w&nbsp;elektromobilność.
                            </p>
                        </div>
                    </section>
                    <section className="fullscreen not-a-toy girl observe chargersObserver2" data-section-type={CONSTANTS.sectionType.light}>
                        <div className="not-a-toy_bg" />
                        <div className="container">
                            <h2 className="not-a-toy_title">
                            To nie zabawka ale...
                            </h2>       
                            <p className="not-a-toy_subtitle">
                                mamy bujną wyobraźnię i&nbsp;wiemy, że 
                                nasze produkty muszą spełniać 
                                najwyższe normy bezpieczeństwa.
                            </p>
                        </div>
                    </section>
                    <section className="fullscreen for-you car observe chargersObserver3" data-section-type={CONSTANTS.sectionType.light}>
                        <div className="for-you_bg" />
                        <div className="container">
                            <h2 className="for-you_title first-part">
                                Dla środowiska, dla oszczędności,
                            </h2>
                            <h2 className="for-you_title second-part">
                                dla Ciebie
                            </h2>
                            <p className="for-you_subtitle">
                                Niezależnie do jakich celów wykorzystujesz
                                pojazd elektryczny, możesz przyczynić
                                się do oszczędności środowiska i&nbsp;pieniędzy.
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}